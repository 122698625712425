import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap"
import { ArrowRightCircle } from "react-bootstrap-icons";
import headerImg from "../assets/img/header-img.svg";
import myPhoto from '../assets/img/me.png'
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
    const [loopNumber, setLoopNumber] = useState(0);
    const [notTipping, setIsNotTipping] = useState(false);
    const whoIam = ["Software Developer", "Web Developer", "Young Entrepreneur", "Backend developer", "Full-Stack Developer", "Technology Enthusiast", "Fitness Enthusiast", "Anime Lover", "Car enthusiasts"];
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const period = 1000;

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta)

        return () => { clearInterval(ticker)};
    }, [text]);

    const tick = () => {
        let i = loopNumber % whoIam.length;
        let fullText = whoIam[i];
        let updatedText = notTipping ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

        setText(updatedText);

        if(notTipping) {
            setDelta(prevDelta => prevDelta / 2);
        }

        if(!notTipping && updatedText === fullText) {
            setIsNotTipping(true);
            setDelta(period);
        } else if(notTipping && updatedText === '') {
            setIsNotTipping(false);
            setLoopNumber(loopNumber + 1);
            setDelta(500);
        }

    }

    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-itens-center">
                    <Col xs={12} md={6} xl={7}>
                        <TrackVisibility>
                        {({ isVisible }) => 
                        <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                            <h2>Hi 👋 , </h2>
                            <h1>{`I'm Gagandeep Dass`}<br></br><div id="whiIamType"><span className="whoIam-word"> {text} </span><span className="blinking-border"></span></div></h1>
                            <p>As a young entrepreneur, I am motivated by a love of learning and continuous improvement. I enjoy collaborating with others, tackling challenges head-on, and finding effective solutions. Outside of work, I am excited to explore new technologies, improve my skills, and keep up with industry trends. <br></br><br></br>And when I’m not coding, you can find me at the gym, where I lift weights and my spirits—because balancing code and squats is how I roll!</p>
                            <button onClick={() => window.open('https://linkedin.com/in/gagandeepdasskaur', '_blank')} className="tagline">Let's Connect <ArrowRightCircle size={25} /></button>
                            <p className="mb-5" style={{fontSize:'14px'}}>contact@gagandeepdass.com</p>
                        </div> }
                        </TrackVisibility>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <div class="myphoto">
                            <img src={myPhoto} alt="Header Img"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}