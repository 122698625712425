import React from 'react';
import '../Timeline.css';

// import images
import freelancer from '../assets/img/freelancer-logo.jpg'
import reactjsimg from '../assets/img/reactjsimg.png';
import nodejsimg from '../assets/img/nodejsimg.png';
import berrly from '../assets/img/berrly.png';
import platziIcon from '../assets/img/platzi.png'
import mongodbimg from '../assets/img/mongodbimg.png';


export const Timeline = () => {

    return (

        <div className='main-timeline' id="timeline">

            <h1>Chronicles of Gagan</h1>

            <div className='timeline'>


            <div className='timeline-container  left-container'>

                <img src={platziIcon} alt='Platzi' />

                <div className='text-box platzicard'>

                    <h2 className='time-line-title tagline'> Platzi </h2><br></br>

                    <small> Data Science </small><br></br>

                    <p>To this day, I continue expanding my knowledge. After the past three years of experience, I have identified my focus in the area of <strong>Data Science</strong>. Therefore, I am pursuing this specialization through <strong>Platzi</strong>, where I am developing skills in data analysis and machine learning.</p>

                    <span className='left-container-arrow'></span>

                </div>

            </div>

                <div className='timeline-container  right-container'>

                    <img src={reactjsimg} alt='React JS' />

                    <div className='text-box reactjscard'>

                        <h2 className='time-line-title tagline'> Advancement in Technologies </h2><br></br>

                        <small>2023 - Present </small><br></br>

                        <p>In 2023, I focused on improving scalable systems and real-time applications. I continued developing my skills in React.js and deepened my experience with Node.js and MongoDB to enhance project efficiency. My current skillset covers both front-end (growing in React.js) and back-end (Node.js, PHP, MongoDB, SQL, PM2), preparing me to handle diverse development challenges.</p>

                        <span className='left-container-arrow'></span>

                    </div>

                </div>



                <div className='timeline-container  left-container'>

                    <img src={nodejsimg} alt='Node JS' />

                    <div className='text-box'>


                        <h2 className='time-line-title tagline'> Admira (Digital Signage)</h2><br></br>

                        <small> Full-Stack Developer | 2022 </small><br></br>

                        <p>
                            By 2022, I took on a more advanced role at <strong>Admira (Digital Signage)</strong> as a Full-Stack Developer. One of my personal achievements <strong>was developing a real-time web-app using Node.js and WebSockets</strong>. During this time, I had my first interaction with <strong>React.js</strong>.
                        </p>

                        <span className='right-container-arrow'></span>

                    </div>

                </div>


                <div className='timeline-container  right-container'>

                    <img src={berrly} alt='Express JS' />

                    <div className='text-box'>

                        <h2 className='time-line-title tagline'>Berrly </h2><br></br>

                        <small> Front-end Developer | 2021 </small><br></br>

                        <p>
                            In 2021, I <strong>joined Berrly</strong> as a Web Developer, where where I focused on front-end development, working on the company’s CRM system. This role allowed me to enhance my problem-solving skills by implementing new features, improving the user interface and experience.
                        </p>

                        <span className='left-container-arrow'></span>

                    </div>

                </div>

                <div className='timeline-container  left-container'>

                    <img src={mongodbimg} alt='MongoDB' />

                    <div className='text-box'>

                        <h2 className='time-line-title tagline'> Growth and Professional Improvement </h2><br></br>

                        <small> 2020 </small>

                        <p>
                            In 2020, I focused on growth and professional improvement, expanding my skillset by working with <strong>PHP</strong> and <strong>MySQL</strong> databases. During this time, I also began <strong>exploring Laravel</strong>, taking my first steps into full-stack development and integrating technologies such as jQuery to enhance my projects.
                        </p>

                        <span className='right-container-arrow'></span>

                    </div>

                </div>

                <div className='timeline-container  right-container'>

                    <img src={freelancer} alt='Freelancer' />

                    <div className='text-box'>

                        <h2 className='time-line-title tagline'> Freelancer </h2><br></br>

                        <small>2019</small>

                        <p>
                            I started my journey in web development in 2019 as a freelancer, creating simple websites for clients using HTML, CSS, and JavaScript.
                        </p>

                        <span className='left-container-arrow'></span>

                    </div>

                </div>

            </div>

        </div>
    );
};